const state = {
  questions: [
    {
      question:
        "What is the current statutory mechanical rate for physical product (like CDs, Cassettes and Vinyl), permanent downloads (sometimes called PDDs) and ringtones? How is it calculated?",
      answer:
        "<p>For 2025, the current statutory mechanical royalty rate for physical products and permanent downloads is $.127 (12.7 cents) per song per unit for recordings of compositions up to five minutes (5:00) in length. The Copyright Royalty Board is responsible for setting the mechanical royalty rates, and you can obtain the latest rates <a href='https://www.ecfr.gov/current/title-37/chapter-III/subchapter-E/part-385#385.11' target='_blank'>here</a>.</p>" +
        "<p>For example, if one were to make a recording of a song that is less than five minutes in length (like, say, 4:07) and then manufacture and distribute 500 units of the recording, the total amount of mechanical royalties due would be $63.50. ($0.127 X 500 units = $63.50).</p>" +
        "<p>For songs over five minutes in length, the mechanical royalty rate is based upon $.0245 (2.45 cents) per minute or fraction of a minute as illustrated below:</p>" +
        "<p>5:01 to 6:00 = $0.147 (6 X $.0245)</p>" +
        "<p>6:01 to 7:00 = $0.1715 (7 X $.0245)</p>" +
        "<p>7:01 to 8:00 = $0.196 (8 X $.0245)</p>" +
        "<p>So, if one were to make a recording of a song that is six minutes and thirty-eight seconds in length (6:38) and then manufacture and distribute 500 units, the total amount of mechanical royalties due is $85.75. ($0.1715 X 500 units = $85.75).</p>" +
        "<p>The current statutory rate for ringtones is $0.24 (24 cents) per song per unit.</p>"
    },
    {
      question: "HFA’s Songfile® rates for interactive streaming.",
      answer:
        '<p>If you are eligible for a Songfile® license, the royalty rate for interactive streams is $0.01 per stream.</p><p>For example, the royalty rate for 500 interactive streams is $5.00.</p><p>The royalty rate for 5,000 interactive streams is $50.00.</p><p>For quantities over 10,000 streams, licenses for interactive streaming can be obtained through our bulk licensing process. If you are interested in obtaining such licenses, please contact us <a target="_blank" href="https://harryfox.com/#/license-music">here</a>. Please note that in order to satisfy your obligations pursuant to the bulk licensing process for the distribution of interactive streams, you must be able to report information such as service revenue, subscriber information, content costs, and applicable performance royalty expenses. Information on the rates for bulk licensing can be found <a target="_blank" href="https://harryfox.com/#/rate-charts">here</a>.</p><p>If you have submitted your sound recordings to a commercial digital music provider, then the digital music provider may be the party responsible for obtaining the license for interactive streaming, unless your agreement with the service requires you to obtain the license.</p>'
    },
    {
      question: "What can be licensed through HFA Songfile®?",
      answer:
        '<p>Songfile® is a simple and fast way to obtain mechanical licenses for a limited number of physical recordings (CDs, cassettes and vinyl only) made and distributed in the U.S., as well as ringtones, permanent downloads ("PDDs") of singles, and certain interactive streams.</p><p>Songfile® may be used to license up to 2,500 units (copies) of a song, for physical recordings, ringtones, and permanent downloads (“PDDs”). If you are distributing more than 2,500 units of physical product or downloads, please set up an HFA Licensing Account. Information on how to do this can be found <a target="_blank" href="https://new.harryfox.com/#/sign-up">here</a>.</p><p>Songfile® may also be used to license a limited quantity minimum of 100 up to 10,000 individual interactive streams. If you have submitted your sound recordings to a commercial digital music provider, then the digital music provider may be the party responsible for obtaining the license for interactive streaming, unless your agreement with the service requires you to obtain the license.</p><p>The application for an HFA Licensing Account can be found <a target="_blank" href="https://harryfox.com/#/setup-hfa-portal">here</a>.</p><p>If you are using Songfile®, there is a $16 processing fee per song license; if more than 5 songs are requested in a transaction, the fee is reduced to $14 for each additional song. A digital invoice is generated before purchase.</p>'
    },
    {
      question:
        "If I obtain a mechanical license from Songfile®, do I need to obtain a mechanical license from the publishers who own the song? ",
      answer:
        "<p>When you obtain a mechanical license for a musical composition from Songfile®, the license will provide the identity and ownership percentage of the music publishers on whose behalf HFA issued the license. If your order includes the transmission of an NOI, the identity of the music publisher to whom the NOI is sent will be provided on the NOI.</p>"
    },
    {
      question: "What are HFA's Songfile® Licensing Terms?  ",
      answer:
        '<p>To view the terms of a standard Songfile® license for all configurations, please click <a target="_blank" href="/sample-license">here</a>.</p>'
    },
    {
      question:
        "How long does it take to process a Notice of Intention (NOI) through Songfile®?",
      answer:
        "<p>The processing time for a Notice of Intention (NOI) through Songfile® can vary. Typically, the verification and processing of an NOI may take up to two business days. However, the exact turnaround time depends on the specific circumstances of each request, including the current song information in HFA’s records. We recommend checking your Songfile® account regularly for updates on your NOI status.</p>"
    },
    {
      question: "How do I obtain print rights for sheet music?",
      answer:
        '<p>HFA does not offer print rights; however, you may secure print rights by contacting the publisher directly. You can use the following databases to locate publisher contact information:</p><p><a target="_blank" href="http://www.ascap.com">http://www.ascap.com</a></p><p><a target="_blank" href="http://www.bmi.com">http://www.bmi.com</a></p><p><a target="_blank" href="http://www.sesac.com">http://www.sesac.com</a></p><p><a target="_blank" href="http://www.copyright.gov">http://www.copyright.gov</a>/</p><p><a target="_blank" href="http://www.themlc.com">http://www.themlc.com</a></p>'
    },
    {
      question: "How do I obtain public performance rights?",
      answer:
        '<p>HFA does not handle public performance rights requests. However, a publisher may assign its public performance rights to one of the following performing rights organizations which may be able to provide to you a license authorizing the public performance of one or more musical compositions:</p><p><a target="_blank" href="http://www.ascap.com">http://www.ascap.com</a></p><p><a target="_blank" href="http://www.bmi.com">http://www.bmi.com</a></p><p><a target="_blank" href="http://www.sesac.com">http://www.sesac.com</a></p><p><a target="_blank" href="http://www.globalmusicrights.com">http://www.globalmusicrights.com</a></p>'
    },
    {
      question: 'How do I obtain sound recording or "master use" rights?',
      answer:
        "<p>Master use rights are required for previously recorded material that you do not own or control. HFA does not issue licenses for master use rights. Those rights may only be obtained from the owner of the master recording or their administrator or agent, often a record company. The owner may be identified on label copy accompanying the sound recording.</p><p>You should obtain the master use license from the authorized party prior to requesting a mechanical license from HFA.</p>"
    },
    {
      question: "How do I license a recording with samples?",
      answer:
        "<p>Sampling is (1) the use of pre-recorded material within another recording, which requires a master use right and/or (2) the use of a portion of a pre-existing musical composition that you don't own or control within another recording (sometimes called “interpolation”), where the use of the recording or musical composition is merged and made a part of the new work.  Before you sample a sound recording or musical composition in your own work, you need to request permission to include the pre-existing recording or song in your new work. The licenses are sometimes known as “sampling” licenses.</p><p>HFA does not process or issue sampling licenses. They can only be obtained directly from the owner of the master recording or their administrator or agent, often a record company, for the use of the master recording and the publisher, for the use of the composition, and include that permission when you apply for a mechanical license through Songfile®.</p>"
    },
    {
      question: "How do I license a recording of a medley?",
      answer:
        "<p>A “medley” is a sound recording made up of a series of pre-existing musical compositions, each of which musical composition retains its separate identity. In order to license a medley, you will need to obtain a mechanical license for each separate musical composition. Songfile® may be able to issue the licenses for the works in a medley if each musical work is represented 100% by an HFA affiliate.</p>"
    },
    {
      question: "How do I license at a reduced rate?",
      answer:
        '<p>HFA is not involved in negotiating royalty rates reduced from the statutory rate. A gratis license (no royalties paid) is also considered a reduced rate. Any reduced rates must be negotiated directly between you and the copyright owner, publisher, or administrator prior to obtaining the mechanical license. Reduced rate or gratis licenses are not available through Songfile®. Once you obtain written permission for the reduced rate, you can obtain a license through HFA by submitting the original copy of that written permission with a standard mechanical license request. You will need to set up a licensee account, which you can do <a target="_blank" href="https://harryfox.com/#/setup-hfa-portal">here</a>. HFA does not issue gratis licenses.</p>'
    },
    {
      question: "Do I need a license for less than 25 CDs, ringtones or PDDs?",
      answer:
        "<p>Yes, it is required under U.S. Copyright Law. This is how the publisher - and ultimately, the songwriter - gets compensated for the use of their song. HFA licenses for a minimum of 25 units of physical products (CDs, cassettes, and vinyl), or 25 ringtones or permanent downloads (PDDs). You can apply for these licenses quickly and easily using Songfile® and pay with a credit card (MasterCard, Visa, or American Express) or from your checking account.</p>"
    },
    {
      question: "Do I need a license for less than 100 interactive streams?",
      answer:
        "<p>Yes, it is required under U.S. Copyright Law. This is how the publisher - and ultimately, the songwriter - gets compensated for the use of their song. HFA licenses a minimum of 100 interactive streams. If you have submitted your sound recordings to a commercial digital music provider, then the digital music provider may be the party responsible for obtaining the license for interactive streaming, unless your agreement with the service requires you to obtain the license.</p><p>You can apply for these licenses quickly and easily using Songfile® and pay with a credit card (MasterCard, Visa, or American Express) or from your checking account. A license for fewer interactive streams must be obtained directly from the publisher.</p>"
    },
    {
      question:
        "Do I still need a license for recordings if I'm not selling them? Isn’t that fair use?",
      answer:
        "<p>Licenses for the use of musical compositions in sound recordings is required regardless of how you distribute your recordings (either by selling or giving away copies). “Fair Use” is a concept in copyright law that certain uses of copyrighted works may not require a license; however, a determination of whether a use falls within the fair use doctrine requires a complex balancing of multiple factors. There is no straightforward test to determine if a use is “fair use,” and you should consult with legal counsel before relying on the fair use doctrine to use a copyrighted work.</p>"
    },
    {
      question: 'What is "Fair Use"?',
      answer:
        '<p>"Fair Use" is a concept in copyright law that certain uses of copyrighted works may not require a license; however, a determination of whether a use falls within the fair use doctrine requires a complex balancing of multiple factors. There is no straightforward test to determine if a use is “fair use,” and you should consult with legal counsel before relying on the fair use doctrine to use a copyrighted work. HFA is unable to advise users as to whether the use falls within the fair use doctrine.</p>'
    },
    {
      question:
        "I want to license a musical composition for use other than a physical product, permanent download, ringtone, or interactive stream. Can I get a license from HFA?",
      answer:
        '<p>Licenses obtained through Songfile® must be distributed either as part of a physical product (like CD) or as a ringtone, permanent download (PDD), or interactive stream, and distributions must be limited by the quantity purchased. Songfile® cannot issue licenses for any other uses.</p><p>HFA may be able to issue licenses through its standard licensing platform. You can apply for a Licensing Account by filling out the application <a target="_blank" href="https://harryfox.com/#/setup-hfa-portal">here</a>.</p><p>Otherwise, you are able to obtain a license by contacting directly the copyright owner of the musical composition. You can use the following databases to locate publisher contact information:</p><p><a target="_blank" href="http://www.ascap.com">http://www.ascap.com</a></p><p><a target="_blank" href="http://www.bmi.com">http://www.bmi.com</a></p><p><a target="_blank" href="http://www.sesac.com">http://www.sesac.com</a></p><p><a target="_blank" href="http://www.copyright.gov">http://www.copyright.gov</a>/</p><p><a target="_blank" href="http://www.themlc.com">http://www.themlc.com</a></p><p>Depending on your operations, you may also be eligible for the blanket license administered by The Mechanical Licensing Collective (“MLC”). More can be learned about the blanket license and The MLC here: <a target="_blank" href="https://themlc.com/">https://themlc.com/</a>.</p>'
    },
    {
      question: "How do I pay my mechanical royalties to HFA?",
      answer:
        "<p>If you have obtained a limited quantity license through HFA Songfile®, you have already paid the mechanical royalties, and you need make no further payment. If you wish to make additional copies beyond the number of units specified in your initial license, or your license has expired, you need to re-apply for another license. Licenses for physical goods have an indefinite term. Licenses for PDDs, ringtones, and interactive streaming are valid for only one year.</p>"
    },
    {
      question:
        "How do I license a new arrangement of a copyright-protected song?",
      answer:
        '<p>Your new version or arrangement of an existing song that alters the melody or character of the song is a derivative work. You need to obtain permission from the publisher directly to create a derivative work and include that permission when you apply for a mechanical license.</p><p>You can use the following databases to locate publisher information:</p><p><a target="_blank" href="http://www.ascap.com">http://www.ascap.com</a></p><p><a target="_blank" href="http://www.bmi.com">http://www.bmi.com</a></p><p><a target="_blank" href="http://www.sesac.com">http://www.sesac.com</a></p><p><a target="_blank" href="http://www.copyright.gov">http://www.copyright.gov</a></p><p><a target="_blank" href="http://www.themlc.com">http://www.themlc.com</a></p>'
    },
    {
      question: "Does HFA license karaoke?",
      answer:
        '<p>No, HFA does not currently license karaoke recordings or downloads. You need to obtain permission from the publisher directly. You can use the following databases to locate publisher information:</p><p><a target="_blank" href="http://www.ascap.com">http://www.ascap.com</a></p><p><a target="_blank" href="http://www.bmi.com">http://www.bmi.com</a></p><p><a target="_blank" href="http://www.sesac.com">http://www.sesac.com</a></p><p><a target="_blank" href="http://www.copyright.gov">http://www.copyright.gov</a></p><p><a target="_blank" href="http://www.themlc.com">http://www.themlc.com</a></p>'
    },
    {
      question: "What is a UPC code and how can I obtain one?",
      answer:
        '<p>The UPC (Universal Product Code or bar code) is issued by GS1 US. A UPC is not required to complete the Songfile® transaction, but a UPC code is required in most point of sale and retail environments. If you would like to obtain one, you can learn more at the <a target="_blank" href="https://www.gs1us.org/upcs-barcodes-prefixes/get-a-barcode">GS1 US website</a>.</p>'
    },
    {
      question: "What is an ISRC and how can I obtain one?",
      answer:
        '<p>The ISRC (International Standard Recording Code) is the international identification system for sound recordings and music video recordings. Each ISRC is a unique and permanent identifier for a specific recording which can be permanently encoded into a product as its digital fingerprint. You can obtain more information about ISRC at <a target="_blank" href="http://www.ifpi.org/content/section_resources/isrc.html">http://www.ifpi.org/content/section_resources/isrc.html</a>.</p>'
    },
    {
      question:
        "How can I license more than 2,500 units of physical or digital product?",
      answer:
        '<p>HFA\'s Songfile® is designed to facilitate limited quantity mechanical licensing. To license more than 2,500 units, please set up a Licensee Account with HFA. Information on how to do this can be found <a target="_blank" href="https://harryfox.com/#/license-music">here</a>.</p>'
    },
    {
      question: "How can I license more than 10,000 interactive streams?",
      answer:
        '<p>HFA\'s Songfile® is designed to facilitate limited quantity mechanical licensing. To license more than 10,000 interactive streams, please set up a Licensee Account with HFA. Information on how to do this can be found <a target="_blank" href="https://harryfox.com/#/license-music">here</a>.</p>'
    },
    {
      question:
        "Why can I find a song in the public search, but not when I search to license it through Songfile®? Why can I license a song for some formats, but not others?",
      answer:
        "<p>Certain publishers have authorized HFA to issue, on their behalf, limited quantify mechanical licenses. Publishers may not always authorize HFA to license their songs for all formats. Additionally, HFA may be able to transmit a Notice of Intention (NOI) to publishers who have registered claims to compositions but have not authorized HFA to issue limited quantity licenses. If the song is not available for licensing through Songfile®, you will need to contact the publisher directly to obtain a license.</p>"
    },
    {
      question: "How do I use the License Reorder Option?",
      answer:
        '<p>Songfile\'s License Reorder option lets you create a new license request from a previous order. You can use this option to quickly complete a license request for additional copies of the same recording; to request licenses for the same recording but for a different format; or to build a new license request using those songs for a different recording (such as a "greatest hits" or a live album).</p>' +
        '<p>To use the License Reorder option, log in to your Songfile® account, click "My Account" and then "My Orders." You will see a list of the completed Songfile® orders. Select which order you wish to reorder by clicking the "view details" button, and then "Reorder" on the bottom left of your order details.</p>' +
        "<p>You will then see the details of your previous order, which will be auto-populated into your new license request. You will have the opportunity to change the licensee and artist information when you check out. You will then verify the type of license configuration (e.g., physical, ringtones, permanent downloads, or interactive stream) and the quantity for your new license.</p>" +
        '<p>Songfile® will then verify HFA\'s current representation of the song. Over time, the identity of publishers who claim rights to a song can change, and the song or its configuration might become ineligible for a Songfile® license or the transmission of an NOI. If the song is ineligible for a Songfile® license or an NOI cannot be transmitted, Songfile® will exclude that song from your shopping cart. Once the status is verified, you will see the Songfile® Cart. If you wish, you can then remove songs from the cart, or you can add additional songs by clicking the "Add A New Release" button.</p>' +
        '<p>After you have entered all the songs you would like for this transaction, click "Proceed to Checkout." The fields on the checkout screens will be auto-populated with the licensee and artist information from the original transaction. Make any necessary changes and complete any additional fields.</p>'
    },
    {
      question: "What is an HFA Song Code?",
      answer:
        "<p>The HFA Song Code is a 6-character unique identifier for a song in HFA's database. You can find the song code on a previously issued HFA mechanical license. It is also included in the Songfile® information for that song.</p>"
    },
    {
      question: "How do I save items in my cart for later?",
      answer:
        '<p>To save items in your cart for later, please click on "Save For Later" when viewing your selections in the cart. This will save your order for 30 days.</p>'
    },
    {
      question: "How do I add multiple songs to one release?",
      answer:
        '<p>Search for your first song and click "Add To Release" on your Songfile® search results page, adding all of the required details. Next, scroll up and repeat this process and add additional songs to your release. Once all desired songs have been added to your release, click on "Review Cart" to review and complete your order or to add a new release.</p>'
    },
    {
      question:
        "When I click on the Cart icon on the top right of my Songfile® page, I am unable to view my existing orders.",
      answer:
        '<p>The Cart icon will only display your current order. To view all saved orders, please click on "My Account" and then on "My Orders."</p>'
    },
    {
      question: "Can I add songs to a completed order?",
      answer:
        '<p>No. When you complete an order, your Saved Cart is emptied and is no longer available through the "View My Cart" option. You must begin a new license search.</p>'
    },
    {
      question: "Does a Songfile® license apply outside the U.S.?",
      answer:
        "<p>No. Licenses obtained through Songfile® authorize the manufacture and distribution within the United States of musical works by way of CDs, cassettes, LPs, ringtones, interactive streams and permanent downloads. If you would like to distribute your release outside of the U.S., you will need to follow the mechanical licensing laws of that country. Note that if you produce your release outside of the U.S., you may also need to obtain licenses in the country of manufacture. You are solely responsible for securing any rights and obtaining any additional license or authority that may be required with respect to any other third parties.</p>"
    }
  ]
};

const getters = {};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
